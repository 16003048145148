import React from 'react';
import { Modal, Header, Button } from 'semantic-ui-react';

function QuickStartModal({ isOpen, onClose }) {
  return (
    <Modal open={isOpen} onClose={onClose} size="small">
      <Modal.Header>Welcome to AWS Re:Invent Planner!</Modal.Header>
      <Modal.Content>
        <p style={{ fontSize: '1.1em', lineHeight: '1.5' }}>
          • Filter your sessions on the left panel.<br />
          • Search for keywords using the search bar at the top.<br />
          • Find your selected sessions by day on the right panel.<br />
          • Click the "View in Map" button to see where you'll be each day.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={onClose}>Got it!</Button>
      </Modal.Actions>
    </Modal>
  );
}

export default QuickStartModal;
