export const hotelPoly = {
  'Encore': [
    [-115.1660552, 36.1300831],
    [-115.1648965, 36.1299271],
    [-115.1638236, 36.1293552],
    [-115.1640382, 36.1285666],
    [-115.1626863, 36.128142],
    [-115.1634159, 36.1267641],
    [-115.1674178, 36.1280207],
    [-115.1660552, 36.1300918]
  ],
  'Wynn': [
    [-115.1688554, 36.1256636],
    [-115.1673641, 36.1281507],
    [-115.163255, 36.1269375],
    [-115.1647356, 36.1225871],
    [-115.1660981, 36.1234364],
    [-115.1666453, 36.124589],
    [-115.167407, 36.1252823],
    [-115.1688554, 36.1256722]
  ],
  'The Venetian': [
    [-115.1680934, 36.1201261],
    [-115.1680076, 36.1221021],
    [-115.1704538, 36.123324],
    [-115.1714408, 36.1219114],
    [-115.1722348, 36.1202214],
    [-115.1681256, 36.1201261]
  ],
  'Caesars Palace': [
    [-115.1679892, 36.1193406],
    [-115.167957, 36.1181965],
    [-115.1669163, 36.1180839],
    [-115.1650602, 36.1181012],
    [-115.1650709, 36.1179972],
    [-115.1643843, 36.1179885],
    [-115.1643843, 36.1198519],
    [-115.165425, 36.1198779],
    [-115.1654357, 36.1193319],
    [-115.1679999, 36.1193579]
  ],
  'MGM Grand': [
    [-115.1676943, 36.1040897],
    [-115.1725545, 36.1040117],
    [-115.1727798, 36.1011424],
    [-115.1675763, 36.1011684],
    [-115.1677051, 36.1041157]
  ],
  'Mandalay Bay': [
    [-115.1732874, 36.0934887],
    [-115.1800787, 36.0933673],
    [-115.1801967, 36.0901248],
    [-115.1731801, 36.0898387],
    [-115.1732444, 36.0934627]
  ]
  // You can add more hotel polygons here as needed
  // values sourced from: https://www.keene.edu/campus/maps/tool/
};
