import React, { useState } from 'react';
import { Card, Pagination } from 'semantic-ui-react';
import './CenterPane.css';

function CenterPane({ sessions, selectedSessions, onSessionSelect, isDarkTheme, isAILoading }) {
  const [activePage, setActivePage] = useState(1);
  const sessionsPerPage = 10;
  const totalPages = Math.ceil(sessions.length / sessionsPerPage);

  const getCardStyle = (isSelected) => ({
    backgroundColor: isSelected
      ? isDarkTheme ? '#1a472a' : '#e6ffe6'  // dark green for dark theme, light green for light theme
      : undefined,  // default background
    transition: 'background-color 0.3s ease',
    ':hover': {
      backgroundColor: isDarkTheme
        ? isSelected ? '#245c3b' : '#2c2c2c'  // slightly lighter dark green when selected, dark gray when not
        : isSelected ? '#d4ffd4' : '#f0f0f0',  // slightly darker light green when selected, light gray when not
    }
  });

  const handlePageChange = (e, { activePage }) => setActivePage(activePage);

  const paginatedSessions = sessions.slice(
    (activePage - 1) * sessionsPerPage,
    activePage * sessionsPerPage
  );

  return (
    <div className="center-pane-container">
      {isAILoading && (
        <div className="large-spinner">
          <div className="spinner-container">
            <div className="spinner-circle"></div>
          </div>
        </div>
      )}
      <Card.Group>
        {paginatedSessions.map(session => {
          const isSelected = selectedSessions.some(s => s.title === session.title);
          return (
            <Card
              key={session.id}
              fluid
              selectable
              onClick={() => onSessionSelect(session)}
              color={isSelected ? 'blue' : null}
              style={getCardStyle(isSelected)}
              className={isSelected ? 'selected' : ''}
            >
              <Card.Content>
                <Card.Header>{session.title}</Card.Header>
                <Card.Meta>
                  {session.hotel} - {new Date(session.date).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' })} at {session.start_time} - Level {session.level}
                </Card.Meta>
                <Card.Description>{session.description}</Card.Description>
              </Card.Content>
            </Card>
          );
        })}
      </Card.Group>
      <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'center' }}>
        <Pagination
          activePage={activePage}
          onPageChange={handlePageChange}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
}

export default CenterPane;
