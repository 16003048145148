import React, { useState } from 'react';
import { Menu, Checkbox, Button, TextArea, Icon } from 'semantic-ui-react';
import AIResponseModal from './AIResponseModal';
import './LeftPane.css';

function LeftPane({ 
  sessionTypes, 
  selectedTypes, 
  onTypeToggle, 
  onSelectAllTypes,
  onDeselectAllTypes,
  hotels, 
  selectedHotels, 
  onHotelToggle, 
  onSelectAllHotels,
  onDeselectAllHotels,
  dates,
  selectedDates,
  onDateToggle,
  levels,
  selectedLevels,
  onLevelToggle,
  onSelectAllLevels,
  onDeselectAllLevels,
  isSessionTypesCollapsed,
  isHotelsCollapsed,
  isDatesCollapsed,
  isLevelsCollapsed,
  onSessionTypesCollapse,
  onHotelsCollapse,
  onDatesCollapse,
  onLevelsCollapse,
  onAISearchStart,
  onAISearchEnd,
  onAddSessions
}) {
  const [aiSearchQuery, setAiSearchQuery] = useState('');
  const [isAIModalOpen, setIsAIModalOpen] = useState(false);
  const [aiResponse, setAiResponse] = useState('');
  const [isAILoading, setIsAILoading] = useState(false);
  const handleAISearchSubmit = async () => {
    setIsAILoading(true);
    onAISearchStart();
    try {
      const systemPrompt = `. The response must include id, title, description, level, date, and hotel. Each of these fields is required in the response. Put Each of these fields in its own line. The format of the response should be:
Id: ID
Title: TITLE
Description: DESCRIPTION
Level: LEVEL
Date: DATE
Hotel: HOTEL
`;
      const response = await fetch('https://plannerdb.com/api/api/query', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt: aiSearchQuery + systemPrompt }),
      });
      const data = await response.json();
      console.log(data);
      setAiResponse(data.response || JSON.stringify(data));
      setIsAIModalOpen(true);
    } catch (error) {
      console.error('Error fetching AI response:', error);
      setAiResponse('Error: Unable to fetch AI response');
      setIsAIModalOpen(true);
    } finally {
      setIsAILoading(false);
      onAISearchEnd();
    }
  };


  return (
    <Menu vertical fluid>
      <Menu.Item>
        <div className="ai-search-container">
          <TextArea
            rows={3}
            placeholder="Can you recommend level 300 sessions for a data scientist for Wednesday?"
            value={aiSearchQuery}
            onChange={(e, { value }) => setAiSearchQuery(value)}
            style={{ marginBottom: '10px', width: '100%' }}
          />
          <Button 
            fluid
            className="rainbow-button"
            style={{
              padding: '15px 30px',
              fontSize: '18px',
              cursor: 'pointer',
              backgroundColor: 'transparent',
              border: 'none',
              borderRadius: '25px',
              position: 'relative',
              overflow: 'hidden',
              transition: 'all 0.3s ease',
              color: '#000', // Ensure text is visible
            }}
            icon={isAILoading ? 'spinner' : 'search'}
            content={isAILoading ? 'Loading...' : 'Ask AI'}
            onClick={handleAISearchSubmit}
            loading={isAILoading}
            disabled={isAILoading}
          />
        </div>
      </Menu.Item>
      <Menu.Item>
        <Button fluid onClick={onSessionTypesCollapse}>
          Session Types {isSessionTypesCollapsed ? '▼' : '▲'}
        </Button>
      </Menu.Item>
      {!isSessionTypesCollapsed && (
        <>
          <Menu.Item>
            <Button.Group fluid>
              <Button onClick={onSelectAllTypes}>Select All</Button>
              <Button onClick={onDeselectAllTypes}>Deselect All</Button>
            </Button.Group>
          </Menu.Item>
          {sessionTypes.map(type => (
            <Menu.Item key={type}>
              <Checkbox 
                label={type}
                checked={selectedTypes.includes(type)}
                onChange={() => onTypeToggle(type)}
              />
            </Menu.Item>
          ))}
        </>
      )}
      <Menu.Item>
        <Button fluid onClick={onHotelsCollapse}>
          Hotels {isHotelsCollapsed ? '▼' : '▲'}
        </Button>
      </Menu.Item>
      {!isHotelsCollapsed && (
        <>
          <Menu.Item>
            <Button.Group fluid>
              <Button onClick={onSelectAllHotels}>Select All</Button>
              <Button onClick={onDeselectAllHotels}>Deselect All</Button>
            </Button.Group>
          </Menu.Item>
          {hotels.map(hotel => (
            <Menu.Item key={hotel}>
              <Checkbox 
                label={hotel}
                checked={selectedHotels.includes(hotel)}
                onChange={() => onHotelToggle(hotel)}
              />
            </Menu.Item>
          ))}
        </>
      )}
      <Menu.Item>
        <Button fluid onClick={onDatesCollapse}>
          Dates {isDatesCollapsed ? '▼' : '▲'}
        </Button>
      </Menu.Item>
      {!isDatesCollapsed && (
        <>
          <Menu.Item>
            <Button.Group fluid>
              <Button onClick={() => dates.forEach(date => onDateToggle(date))}>Select All</Button>
              <Button onClick={() => selectedDates.forEach(date => onDateToggle(date))}>Deselect All</Button>
            </Button.Group>
          </Menu.Item>
          {dates.map(date => (
            <Menu.Item key={date}>
              <Checkbox 
                label={date}
                checked={selectedDates.includes(date)}
                onChange={() => onDateToggle(date)}
              />
            </Menu.Item>
          ))}
        </>
      )}
      <Menu.Item>
        <Button fluid onClick={onLevelsCollapse}>
          Levels {isLevelsCollapsed ? '▼' : '▲'}
        </Button>
      </Menu.Item>
      {!isLevelsCollapsed && (
        <>
          <Menu.Item>
            <Button.Group fluid>
              <Button onClick={onSelectAllLevels}>Select All</Button>
              <Button onClick={onDeselectAllLevels}>Deselect All</Button>
            </Button.Group>
          </Menu.Item>
          {levels.map(level => (
            <Menu.Item key={level}>
              <Checkbox 
                label={`Level ${level}`}
                checked={selectedLevels.includes(level)}
                onChange={() => onLevelToggle(level)}
              />
            </Menu.Item>
          ))}
        </>
      )}
      <AIResponseModal
        isOpen={isAIModalOpen}
        onClose={() => setIsAIModalOpen(false)}
        response={aiResponse}
        onAddSessions={onAddSessions}
      />
    </Menu>
  );
}

export default LeftPane;
