import React, { useState } from 'react';
import { Card, Header, Icon, Button } from 'semantic-ui-react';
import MapModal from './MapModal';

function RightPane({ conferenceDays, selectedSessions, isDarkTheme, onRemoveSession }) {
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);

  const getSessionCardColor = () => {
    return isDarkTheme ? '#2d2d2d' : '#f5f5f5';
  };

  return (
    <div>
      <Button
        primary
        disabled={selectedSessions.length === 0}
        onClick={() => setIsMapModalOpen(true)}
        style={{ marginBottom: '1rem' }}
      >
        View in Map
      </Button>
      <MapModal
        isOpen={isMapModalOpen}
        onClose={() => setIsMapModalOpen(false)}
        selectedSessions={selectedSessions}
      />
      {conferenceDays.map(day => (
        <Card 
          key={day} 
          fluid 
          style={{
            aspectRatio: selectedSessions.filter(session => session.date === day).length === 0 ? '1 / 1' : 'auto',
            backgroundColor: isDarkTheme ? '#1a1919' : '#f5f5f5',
	    paddingRight: "20px"
          }}
        >
          <Card.Content>
            <Card.Header as="h3">
              {new Date(day).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' })}
            </Card.Header>
            <Card.Group>
              {selectedSessions
                .filter(session => session.date === day)
                .sort((a, b) => {
                  const timeA = new Date(`1970-01-01T${a.start_time}`);
                  const timeB = new Date(`1970-01-01T${b.start_time}`);
                  return timeA - timeB;
                })
                .map(session => (
                  <Card 
                    key={session.id} 
                    fluid 
                    style={{ backgroundColor: getSessionCardColor() }}
                  >
                    <Card.Content>
                      <Icon
                        name="remove"
                        color="red"
                        style={{ float: 'right', cursor: 'pointer' }}
                        onClick={() => onRemoveSession(session)}
                      />
                      <Card.Header>{session.title}</Card.Header>
                      <Card.Meta>{session.start_time}</Card.Meta>
                      <Card.Description>{session.description}</Card.Description>
                    </Card.Content>
                  </Card>
                ))}
            </Card.Group>
          </Card.Content>
        </Card>
      ))}
    </div>
  );
}

export default RightPane;
