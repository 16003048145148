import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Header, Button, Input } from 'semantic-ui-react';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { toggleSessionSelection } from './utils/sessionUtils';
import 'react-toastify/dist/ReactToastify.css';
import LeftPane from './components/LeftPane';
import CenterPane from './components/CenterPane';
import RightPane from './components/RightPane';
import MapModal from './components/MapModal';
import QuickStartModal from './components/QuickStartModal';
import { toast } from 'react-toastify';
import './components/Loading.css';
import './App.css';
import elasticlunr from 'elasticlunr';

function App() {
  const [isAILoading, setIsAILoading] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [sessionTypes, setSessionTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [selectedHotels, setSelectedHotels] = useState([]);
  const [conferenceDays, setConferenceDays] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [levels, setLevels] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [isSessionTypesCollapsed, setIsSessionTypesCollapsed] = useState(true);
  const [isHotelsCollapsed, setIsHotelsCollapsed] = useState(true);
  const [isDatesCollapsed, setIsDatesCollapsed] = useState(true);
  const [isLevelsCollapsed, setIsLevelsCollapsed] = useState(true);
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchIndex, setSearchIndex] = useState(null);
  const [isQuickStartModalOpen, setIsQuickStartModalOpen] = useState(false);

  useEffect(() => {
    const hasSeenQuickStart = localStorage.getItem('hasSeenQuickStart');
    if (!hasSeenQuickStart) {
      setIsQuickStartModalOpen(true);
      localStorage.setItem('hasSeenQuickStart', 'true');
    }
    setIsLoading(true);
    axios.get('https://plannerdb.com/api/sessions')
      .then(response => {
        setSessions(response.data);
        
        // Extract unique session types
        const types = [...new Set(response.data.map(session => session.session_type))].sort();
        setSessionTypes(types);
        setSelectedTypes(types); // Initially select all types

        // Extract unique hotels
        const uniqueHotels = [...new Set(response.data.map(session => session.hotel))].sort();
        setHotels(uniqueHotels);
        setSelectedHotels(uniqueHotels); // Initially select all hotels

        // Extract unique conference days
        const days = [...new Set(response.data.map(session => session.date))].sort();
        setConferenceDays(days);
        setSelectedDates(days); // Initially select all dates

        // Extract unique levels
        const uniqueLevels = [...new Set(response.data.map(session => session.level))].sort();
        setLevels(uniqueLevels);
        setSelectedLevels(uniqueLevels); // Initially select all levels

        // Create search index
        const index = elasticlunr(function () {
          this.addField('title');
          this.addField('description');
          this.setRef('id');
        });

        response.data.forEach(session => {
          index.addDoc(session);
        });

        setSearchIndex(index);

        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching sessions:', error);
        setIsLoading(false);
      });
  }, []);

  const handleTypeToggle = (type) => {
    setSelectedTypes(prevTypes => 
      prevTypes.includes(type)
        ? prevTypes.filter(t => t !== type)
        : [...prevTypes, type]
    );
  };

  const handleSelectAllTypes = () => {
    setSelectedTypes([...sessionTypes]);
  };

  const handleDeselectAllTypes = () => {
    setSelectedTypes([]);
  };

  const handleHotelToggle = (hotel) => {
    setSelectedHotels(prevHotels => 
      prevHotels.includes(hotel)
        ? prevHotels.filter(h => h !== hotel)
        : [...prevHotels, hotel]
    );
  };

  const handleSelectAllHotels = () => {
    setSelectedHotels([...hotels]);
  };

  const handleDeselectAllHotels = () => {
    setSelectedHotels([]);
  };

  const handleDateToggle = (date) => {
    setSelectedDates(prevDates => 
      prevDates.includes(date)
        ? prevDates.filter(d => d !== date)
        : [...prevDates, date]
    );
  };

  const handleLevelToggle = (level) => {
    setSelectedLevels(prevLevels => 
      prevLevels.includes(level)
        ? prevLevels.filter(l => l !== level)
        : [...prevLevels, level]
    );
  };

  const handleSelectAllLevels = () => {
    setSelectedLevels([...levels]);
  };

  const handleDeselectAllLevels = () => {
    setSelectedLevels([]);
  };

  const handleSessionTypesCollapse = () => {
    setIsSessionTypesCollapsed(!isSessionTypesCollapsed);
  };

  const handleHotelsCollapse = () => {
    setIsHotelsCollapsed(!isHotelsCollapsed);
  };

  const handleDatesCollapse = () => {
    setIsDatesCollapsed(!isDatesCollapsed);
  };

  const handleLevelsCollapse = () => {
    setIsLevelsCollapsed(!isLevelsCollapsed);
  };

  const handleSelectingSession = (session) => {
    setSelectedSessions(prevSelected => {
      const isAlreadySelected = prevSelected.some(s => s.id === session.id);
      let newSelected;
      if (isAlreadySelected) {
        newSelected = prevSelected.filter(s => s.id !== session.id);
      } else {
        newSelected = [...prevSelected, session];
      }
      console.log('Previous selected:', prevSelected.map(s => s.id));
      console.log('New session:', session.id);
      console.log('New selected:', newSelected.map(s => s.id));
      return newSelected;
    });
  }

  const handleRemoveSession = (session) => {
    setSelectedSessions(prevSelected => {
      const newSelected = prevSelected.filter(s => s.id !== session.id);
      toast.info(`Removed session: ${session.title}`);
      return newSelected;
    });
  }

  const handleAddSessions = (sessions) => {
    setSelectedSessions(prevSelected => {
      const newSessions = sessions.filter(session => !prevSelected.some(s => s.id === session.id));
      toast.success(`Added ${newSessions.length} sessions`);
      return [...prevSelected, ...newSessions];
    });
  }

  const handleSearch = (event, { value }) => {
    setSearchTerm(value);
  };

  const onAISearchStart = () => {
    setIsAILoading(true);
  };

  const onAISearchEnd = () => {
    setIsAILoading(false);
  };

  const filteredSessions = useMemo(() => {
    let filtered = sessions.filter(session => 
      selectedTypes.includes(session.session_type) && 
      selectedHotels.includes(session.hotel) &&
      selectedDates.includes(session.date) &&
      selectedLevels.includes(session.level)
    );

    if (searchTerm && searchIndex) {
      const searchResults = searchIndex.search(searchTerm, {
        fields: {
          title: {boost: 2},
          description: {boost: 1}
        }
      });
      const searchResultIds = new Set(searchResults.map(result => result.ref));
      filtered = filtered.filter(session => searchResultIds.has(session.id.toString()));
    }

    return filtered;
  }, [sessions, selectedTypes, selectedHotels, selectedDates, selectedLevels, searchTerm, searchIndex]);

  return (
    <div className={isDarkTheme ? 'dark-theme' : 'light-theme'}>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
      <Grid>
        <Grid.Row>
          <Grid.Column width={12}>
            <Header as="h1" textAlign="center">AWS Re:Invent Planner</Header>
          </Grid.Column>
          <Grid.Column width={3}>
            <Input
              icon='search'
              placeholder='Search sessions...'
              onChange={handleSearch}
              value={searchTerm}
            />
          </Grid.Column>
          <Grid.Column width={1}>
            <Button
              toggle
              active={isDarkTheme}
              onClick={() => setIsDarkTheme(!isDarkTheme)}
              basic={!isDarkTheme}
              color={isDarkTheme ? 'grey' : 'blue'}
            >
              {isDarkTheme ? 'Light' : 'Dark'}
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            <LeftPane 
              sessionTypes={sessionTypes} 
              selectedTypes={selectedTypes}
              onTypeToggle={handleTypeToggle}
              onSelectAllTypes={handleSelectAllTypes}
              onDeselectAllTypes={handleDeselectAllTypes}
              hotels={hotels}
              selectedHotels={selectedHotels}
              onHotelToggle={handleHotelToggle}
              onSelectAllHotels={handleSelectAllHotels}
              onDeselectAllHotels={handleDeselectAllHotels}
              dates={conferenceDays}
              selectedDates={selectedDates}
              onDateToggle={handleDateToggle}
              levels={levels}
              selectedLevels={selectedLevels}
              onLevelToggle={handleLevelToggle}
              onSelectAllLevels={handleSelectAllLevels}
              onDeselectAllLevels={handleDeselectAllLevels}
              isSessionTypesCollapsed={isSessionTypesCollapsed}
              isHotelsCollapsed={isHotelsCollapsed}
              isDatesCollapsed={isDatesCollapsed}
              isLevelsCollapsed={isLevelsCollapsed}
              onSessionTypesCollapse={handleSessionTypesCollapse}
              onHotelsCollapse={handleHotelsCollapse}
              onDatesCollapse={handleDatesCollapse}
              onLevelsCollapse={handleLevelsCollapse}
              onAISearchStart={onAISearchStart}
              onAISearchEnd={onAISearchEnd}
              onAddSessions={handleAddSessions}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <CenterPane 
              sessions={filteredSessions} 
              selectedSessions={selectedSessions}
              onSessionSelect={(session) => handleSelectingSession(session)}
              isDarkTheme={isDarkTheme}
              isAILoading={isAILoading}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <RightPane 
              conferenceDays={conferenceDays} 
              selectedSessions={selectedSessions}
              isDarkTheme={isDarkTheme}
              onRemoveSession={handleRemoveSession}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ToastContainer position="bottom-right" autoClose={3000} />
      <QuickStartModal
        isOpen={isQuickStartModalOpen}
        onClose={() => setIsQuickStartModalOpen(false)}
      />
    </div>
  );
}

export default App;
