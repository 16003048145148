import React, { useEffect, useRef, useState } from 'react';
import { Modal, Header, Dropdown } from 'semantic-ui-react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { hotelPoly } from './hotelPolygons';

function MapModal({ isOpen, onClose, selectedSessions }) {
  const mapRef = useRef();
  const mapInstanceRef = useRef(null);
  const markersLayerRef = useRef(null);
  const polygonsLayerRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState('All');

  const hotelCoordinates = {
    'Encore': [36.1271, -115.1646],
    'Wynn': [36.1275, -115.1641],
    'The Venetian': [36.1211, -115.1692],
    'MGM Grand': [36.1026, -115.1703],
    'Caesar\'s': [36.1162, -115.1740],
    'Mandalay Bay': [36.0918, -115.1750],
    'Bellagio': [36.1129, -115.1770],
    'Aria': [36.1072, -115.1778],
    'Paris Las Vegas': [36.1125, -115.1717],
    'Luxor': [36.0955, -115.1756],
    'Excalibur': [36.0988, -115.1753],
  };

  const createPolygon = (center, radius, hotelName) => {
    if (hotelPoly[hotelName]) {
      return hotelPoly[hotelName].map(([lng, lat]) => [lat, lng]);
    }

    const points = 5;
    const vertices = [];
    for (let i = 0; i < points; i++) {
      const angle = (i / points) * (2 * Math.PI);
      const lat = center[0] + (radius * Math.cos(angle) / 111111);
      const lng = center[1] + (radius * Math.sin(angle) / (111111 * Math.cos(center[0] * (Math.PI / 180))));
      vertices.push([lat, lng]);
    }
    return vertices;
  };

  const updateMap = () => {
    if (mapInstanceRef.current && markersLayerRef.current && polygonsLayerRef.current) {
      markersLayerRef.current.clearLayers();
      polygonsLayerRef.current.clearLayers();

      const filteredSessions = selectedDate === 'All'
        ? selectedSessions
        : selectedSessions.filter(session => session.date === selectedDate);

      const hotelSessions = {};

      filteredSessions.forEach(session => {
        if (!hotelSessions[session.hotel]) {
          hotelSessions[session.hotel] = [];
        }
        hotelSessions[session.hotel].push(session);
      });

      Object.entries(hotelSessions).forEach(([hotel, sessions]) => {
        const coords = hotelCoordinates[hotel];
        if (coords) {
          const marker = L.marker(coords)
            .bindPopup(`<b>${hotel}</b><br>${sessions.map(s => s.title).join('<br>')}`)
            .addTo(markersLayerRef.current);

          const polygonCoords = createPolygon(coords, 200, hotel);
          const polygon = L.polygon(polygonCoords, {
            color: 'red',
            fillColor: '#f03',
            fillOpacity: 0.3
          }).addTo(polygonsLayerRef.current);

          polygon.bindPopup(`<b>${hotel}</b><br>${sessions.length} session(s)`);
        }
      });
    }
  };

  useEffect(() => {
    if (isOpen && mapRef.current && !mapInstanceRef.current) {
      mapInstanceRef.current = L.map(mapRef.current).setView([36.1126, -115.1703], 13);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(mapInstanceRef.current);

      markersLayerRef.current = L.layerGroup().addTo(mapInstanceRef.current);
      polygonsLayerRef.current = L.layerGroup().addTo(mapInstanceRef.current);
    }

    if (mapInstanceRef.current) {
      updateMap();
    }

    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.remove();
        mapInstanceRef.current = null;
        markersLayerRef.current = null;
        polygonsLayerRef.current = null;
      }
    };
  }, [isOpen, selectedSessions, selectedDate]);

  const dateOptions = [
    { key: 'all', text: 'All', value: 'All' },
    ...Array.from(new Set(selectedSessions.map(session => session.date)))
      .sort((a, b) => new Date(a) - new Date(b))
      .map(date => ({ key: date, text: date, value: date }))
  ];

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header>Your AWS Re:Invent Sessions</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem', position: 'relative', zIndex: 1000 }}>
            <Header style={{ marginBottom: 0, marginRight: '1rem' }}>Session Locations</Header>
            <Dropdown
              selection
              options={dateOptions}
              value={selectedDate}
              onChange={(_, { value }) => setSelectedDate(value)}
            />
          </div>
          <div ref={mapRef} style={{ height: '400px', width: '100%' }}></div>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

export default MapModal;
