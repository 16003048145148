import { toast } from 'react-toastify';

export const toggleSessionSelection = (prevSelected, session) => {
  const isAlreadySelected = prevSelected.some(s => s.id === session.id);
  if (isAlreadySelected) {
    toast.info(`Removed session: ${session.title}`);
    return prevSelected.filter(s => s.id !== session.id);
  } else {
    toast.success(`Added session: ${session.title}`);
    return [...prevSelected, session];
  }
};
