import React from 'react';
import { Modal, Button, Table } from 'semantic-ui-react';

function AIResponseModal({ isOpen, onClose, response, onAddSessions }) {
  const parseResponse = (responseString) => {
    console.log(response);
    const sessions = [];
    const lines = responseString.split('\n');
    let currentSession = {};

    lines.forEach(line => {
      if (line.startsWith('Title:')) {
        if (Object.keys(currentSession).length > 0) {
          sessions.push(currentSession);
          currentSession = {};
        }
        currentSession.title = line.replace('Title:', '').trim();
      } else if (line.startsWith('Description:')) {
        currentSession.description = line.replace('Description:', '').trim();
      } else if (line.startsWith('Level:')) {
        currentSession.level = line.replace('Level:', '').trim();
      } else if (line.startsWith('Hotel:')) {
        currentSession.hotel = line.replace('Hotel:', '').trim();
      } else if (line.startsWith('Date:')) {
        currentSession.date = line.replace('Date:', '').trim();
      }
    });

    if (Object.keys(currentSession).length > 0) {
      sessions.push(currentSession);
    }

    return sessions;
  };

  const sessions = parseResponse(response);

  return (
    <Modal open={isOpen} onClose={onClose} size="large">
      <Modal.Header>AI-Powered Search Result</Modal.Header>
      <Modal.Content scrolling>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Title</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Level</Table.HeaderCell>
              <Table.HeaderCell>Hotel</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sessions.map((session, index) => (
              <Table.Row key={index}>
                <Table.Cell>{session.title}</Table.Cell>
                <Table.Cell>{session.description}</Table.Cell>
                <Table.Cell>{session.level}</Table.Cell>
                <Table.Cell>{session.hotel}</Table.Cell>
                <Table.Cell>{session.date}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => {
          onAddSessions(sessions);
          onClose();
        }}>Add</Button>
        <Button onClick={onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
}

export default AIResponseModal;
